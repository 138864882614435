<template>
  <div>
    <button type="button" class="btn btn-success btn-lg btn-block" @click="onStartCourse">Start the course</button>
    <button type="button" v-if="!showEmailInput" class="btn btn-info btn-lg btn-block" @click="onExcerpt">Download Sample Videos</button>
    <div v-else class="mt-3">
      <p>Input your email address to receive free videos:</p>
      <form @submit.prevent="onExcerptFormSubmitted">
        <div class="input-group input-group-lg ">
            <input v-model="customerEmail" name="email" required type="email" class="form-control" aria-label="Email" aria-describedby="inputGroup-sizing-lg" placeholder="E-mail address">
            <button type="submit" class="btn btn-success"> OK</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AuthHelper from "@/authHelper";
import Webservice from "@/webservice";
import Consts from "@/consts";

export default {
  name: "StartCourseButton",
  props: {
    productId: String
  },
  data() {
    return {
      showEmailInput: false,
      customerEmail: null,
      tutorialId: process.env.VUE_APP_MAIN_TUTORIAL_ID,
    }
  },
  methods: {
    onExcerptFormSubmitted(){
      AuthHelper.getOrCreateAnonUser().then((user)=> {
        Webservice.tracking({
          action: {
            referrer: document.referrer,
            lang: navigator.language || navigator.userLanguage,
            name: "REQUEST_EXCERPT_"+this.tutorialId,
            utm: {
              source: this.$route.query.utm_source,
              medium: this.$route.query.utm_medium,
              campaign: this.$route.query.utm_campaign,
              term: this.$route.query.utm_term,
              content: this.$route.query.utm_content,
            }
          }
        }, user.idToken)
      })
      Webservice.getExcerpt(this.tutorialId,this.customerEmail).then(()=> {
        this.makeToast("Success", "Email with free videos sent !", "success")
      }).catch((err)=> {
        console.error("impossible to send excerpt :"+err)
        this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
      })
    },
    onExcerpt(){
      this.showEmailInput = true
    },
    onStartCourse(){
      // get current user & create anonymous user if no firebase user
      AuthHelper.getOrCreateAnonUser().then((user)=> {
        Webservice.addToCart(this.productId,1, user.idToken).then((res)=> {
          if (res.status === 204) {
            // not in stock
            //this.makeToast("Error", "Impossible to add to cart, this item is not in stock anymore", "danger")
            this.errorMessage = "Impossible to add to cart, this item is not in stock anymore"
            this.showErrorAlert = true
            return
          }
          AuthHelper.getOrCreateAnonUser().then((user)=> {
            Webservice.tracking({
              action: {
                referrer: document.referrer,
                lang: navigator.language || navigator.userLanguage,
                name: "ADD_TO_CART_TUTO_"+this.tutorialId,
                utm: {
                  source: this.$route.query.utm_source,
                  medium: this.$route.query.utm_medium,
                  campaign: this.$route.query.utm_campaign,
                  term: this.$route.query.utm_term,
                  content: this.$route.query.utm_content,
                }
              }
            }, user.idToken)
          })
          this.makeToast("Success", "Added to cart ! The product is reserved 20 minutes for you !", "success")
          this.$router.push({name:Consts.ROUTE_NAMES.CART})
        }).catch((err)=> {
          console.error("impossible to add to cart :"+err)
          this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
        })
      }).catch((err)=>{
        // What to do ?
        console.error("error while retrieving the current user:"+err)
        this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")

      })
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: true
      })
    },
  }
}
</script>

<style scoped>

</style>