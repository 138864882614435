<template>
  <div class="row mt-2">
    <div class="col">
      <h3>Table of contents:</h3>
      <ul class="list-group mt-3">
        <li class="list-group-item" v-for="(lesson, idx) in lessons" :key="idx">
          <div class="row">
            <!-- first col-->
            <div class="col-md-1 col-2">
              <div class="m-2">
                <div class="btn" v-if="isPromotional">
                  <div v-if="!lesson.isPremium">
                    <a @click="onClickLesson(lesson)">
                      <i  class="bi bi-play-circle-fill" style="font-size: 2rem; color: cornflowerblue;"></i>
                    </a>
                  </div>
                  <i v-else class="bi bi-file-lock2" style="font-size: 2rem; color: cornflowerblue;"></i>
                </div>
                <div class="btn" v-else>
                  <a @click="onClickLesson(lesson)" >
                    <i class="bi bi-play-circle-fill" style="font-size: 2rem; color: cornflowerblue;"></i>
                  </a>
                </div>
              </div>
            </div>
            <!-- second col-->
            <div class="col-md-9 col-9">
              <a @click="onClickLesson(lesson)" >
                <h6>{{ lesson.title }}</h6>
                <div v-if="isPromotional">
                  <p class="small"> {{lesson.duration}}
                    <span v-if="lesson.isPremium" class="badge badge-warning">Premium</span>
                    <span v-else class="badge badge-success">Free</span>
                  </p>
                </div>
                <p v-else>{{lesson.duration}}</p>
              </a>
              <b-form-checkbox
                  v-if="showCheckbox"
                  v-model="lesson.completed"
                  @change="onCompleteLesson(lesson)"
              >
              </b-form-checkbox>

              <span class="font-weight-light">What we will do:</span><br>
              <ul>
                <li v-for="(what,idxWhat) in lesson.what" :key="idxWhat">{{what}}</li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Consts from "@/consts";

export default {
  name: "TableOfContentTutorial",
  props: {
    tutorialId : String,
    lessons:Array,
    showCheckbox: Boolean,
    isPromotional: Boolean,
  },
  data() {
    return {
      routeNames: Consts.ROUTE_NAMES,
    }
  },
  methods: {
    onClickLesson(lesson){
      this.$emit("click-lesson",lesson)
    },
    onCompleteLesson(lesson){
      this.$emit("complete-lesson",lesson)
    },
  }
}
</script>

<style scoped>

</style>