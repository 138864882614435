<template>
  <div>
    <NavBar class="mb-2"></NavBar>
    <b-container>
      <h1 style=" color: #9fafcd;">Build an e-commerce website with Go, Vue.js and AWS | Complete training 2024</h1>
      <div class="mb-3">
        <i class="bi bi-stopwatch" style="font-size: 1.2rem; color: cornflowerblue;"></i><span class="font-weight-bold ml-2">Duration:</span> <span v-if="!loading">{{ totalDuration }}</span> <span class="small">({{exactDuration}})</span><br>
        <i class="bi bi-thermometer-low" style="font-size: 1.2rem; color: cornflowerblue;"></i><span class="font-weight-bold ml-2">Level:</span> Easy - convenient for beginners<br>
      </div>
       <div class="row">
        <div class="col-md-9">
          <Loader v-if="loading"></Loader>
          <div class="embed-responsive embed-responsive-16by9" v-else>
            <iframe
                class="embed-responsive-item"
                :src="this.mainVideoUrl"
                style="border: none;"
                height="720"
                width="1280"
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowfullscreen="true"
            ></iframe>
          </div>
        </div>
         <!-- Course contains -->
        <div class="col mt-2">
          <h3>This course contains :</h3>
          <i class="bi bi-webcam" style="font-size: 1.2rem; color: cornflowerblue;"></i><span class="ml-2"><span v-if="!loading" >{{lessons.length}}</span> on-demand videos</span>  <br>
          <i class="bi bi-clock" style="font-size: 1.2rem; color: cornflowerblue;"></i><span class="ml-2">Duration: <span v-if="!loading" >{{exactDuration}}</span></span>  <br>
          <i class="bi  bi-folder-check" style="font-size: 1.2rem; color: cornflowerblue;"></i><span class="ml-2">Code:</span> 3 complete projects <br>
          <i class="bi bi-arrow-clockwise" style="font-size: 1.2rem; color: cornflowerblue;"></i><span class="ml-2">Unlimited access</span>  <br>
          <i class="bi bi-cloud-plus" style="font-size: 1.2rem; color: cornflowerblue;"></i><span class="ml-2">Free Updates</span>  <br>

          <hr>
          <div class="row">
            <div class="col-5">
              <b-img rounded="circle" thumbnail width="200px" alt="Maximilien Andile" :src="require('@/assets/images/me.png')"></b-img>
            </div>
            <div class="col">
              <h5>By Maximilien Andile</h5>
            </div>
          </div>
        </div>
      </div>
      <br>
      <h4>Course description: </h4>
      <div class="row mt-4">
        <div class="col-12 col-md-9">
          <p>The best way to learn a language is to <strong>build a real-world project</strong>. I do this with my students in real life, and it is always effective!</p>
          <p>I constructed this course to teach you how to create <strong>from A to Z</strong>:</p>
          <ul>
            <li><strong>Go backend</strong> applications that will expose a REST API</li>
            <li><strong>Vue.js Frontend</strong> applications that will consume a REST API</li>
          </ul>
          <p>We will also learn how to <strong>deploy those applications to the AWS (Amazon Web Services) cloud provider</strong>. I will guide you at each step of the process.</p>

          <h6>Structure</h6>
          <p>This course is composed of <strong v-if="!this.loading">{{ this.lessons.length }} video lectures </strong> with a total <strong>duration of {{ this.totalDuration }}.</strong></p>
          <p>You will find in the sessions application exercises that will allow you to practice at your pace. The corrections of exercises are filmed. That way, you can learn from your errors and see me coding.</p>
          <p>You will also find some theory in sessions when needed!</p>
          <h6>Audience</h6>
          <p>If you want to get started with programming, you can take this course as I tried to make it <strong>accessible to everyone</strong>. The only pre-requisite is to have a computer at your disposal.
            Experienced developers can also be interested in this course if they want to strengthen their knowledge.</p>
        </div>
        <div class="col-12 col-md-3">
          <h6>What ?</h6>
          <ul>
            <li>Real Word Application</li>
            <li>Go in the Backend</li>
            <li>Vue.js in the Frontend (HTML,CSS,JS)</li>
            <li>Fully deployed on AWS</li>
            <li>With A Serverless approach</li>
          </ul>
          <h6>How ?</h6>
          <ul>
            <li v-if="!this.loading">{{this.lessons.length}} videos</li>
            <li>With exercises</li>
            <li>Code downloadable</li>
            <li>Theory when needed</li>
          </ul>
          <h6>For who ?</h6>
          <ul>
            <li>Total Beginners</li>
            <li>Also convenient for experienced devs</li>
          </ul>
        </div>
      </div>

      <!-- Goal -->
      <div class="row mt-4" >
        <div class="col-12 col-md-5 p-3 pl-4" style="background-color: #f5e5f5">
          <h3><i class="bi bi-lightbulb"></i> What you’ll learn:</h3>
          <i class="bi bi-check-circle-fill what-you-will-learn-icon" ></i> Use Go to build an HTTP web server<br>
          <i class="bi bi-check-circle-fill what-you-will-learn-icon" ></i> Use Git and Github<br>
          <i class="bi bi-check-circle-fill what-you-will-learn-icon" ></i> Build a Go HTTP server<br>
          <i class="bi bi-check-circle-fill what-you-will-learn-icon" ></i> Use Go Modules<br>
          <i class="bi bi-check-circle-fill what-you-will-learn-icon" ></i> Unit test your Go code<br>
          <i class="bi bi-check-circle-fill what-you-will-learn-icon" ></i> Build and use mocks<br>
          <i class="bi bi-check-circle-fill what-you-will-learn-icon" ></i> Use the Gin Web Framework<br>
          <i class="bi bi-check-circle-fill what-you-will-learn-icon" ></i> The basics of HTML and CSS<br>
          <i class="bi bi-check-circle-fill what-you-will-learn-icon" ></i> Build Vue.Js applications<br>
          <i class="bi bi-check-circle-fill what-you-will-learn-icon" ></i> Use npm<br>
          <i class="bi bi-check-circle-fill what-you-will-learn-icon" ></i> Use the serverless framework<br>
          <i class="bi bi-check-circle-fill what-you-will-learn-icon" ></i> Deploy an AWS API Gateway (with serverless)<br>
          <i class="bi bi-check-circle-fill what-you-will-learn-icon" ></i> Deploy an AWS Lamdba<br>
          <i class="bi bi-check-circle-fill what-you-will-learn-icon" ></i> Make requests to a DynamoDb database<br>
          <i class="bi bi-check-circle-fill what-you-will-learn-icon" ></i> Use database transactions<br>
          <i class="bi bi-check-circle-fill what-you-will-learn-icon" ></i> Send emails with AWS<br>
          <i class="bi bi-check-circle-fill what-you-will-learn-icon" ></i> Deploy a Frontend application with AWS Amplify<br>
          <i class="bi bi-check-circle-fill what-you-will-learn-icon" ></i> Integrate Stripe payments with Go<br>
          <i class="bi bi-check-circle-fill what-you-will-learn-icon" ></i> Handle HTTP webhooks from Stripe<br>
          <i class="bi bi-check-circle-fill what-you-will-learn-icon" ></i> Setup a domain name in AWS<br>
          <i class="bi bi-check-circle-fill what-you-will-learn-icon" ></i> Setup a CDN in AWS<br>
        </div>
        <!-- Tools used -->
        <div class="col-12 col-md-6 p-3 pl-4 ml-3 mt-3" style="background-color: #ffffff">
          <h4><i class="bi bi-lightbulb"></i> What we will use:</h4>
          <b-img :src="require('@/assets/images/tuto/Go-Logo_LightBlue.jpg')" alt="Go Language"  fluid thumbnail class="img-book" width="100px"></b-img>
          <b-img :src="require('@/assets/images/tuto/gin.jpg')" alt="Gin"  fluid thumbnail class="img-book" width="100px"></b-img>

          <b-img :src="require('@/assets/images/tuto/vue_logo.jpg')" alt="Vue.js"  fluid thumbnail class="img-book" width="100px"></b-img>
          <b-img :src="require('@/assets/images/tuto/html5_new.png')" alt="HTML 5"  fluid thumbnail class="img-book" width="100px"></b-img>
          <b-img :src="require('@/assets/images/tuto/css3.webp')" alt="CSS 3"  fluid thumbnail class="img-book" width="100px"></b-img>
          <b-img :src="require('@/assets/images/tuto/bootstrap.jpg')" alt="Bootstrap v5"  fluid thumbnail class="img-book" width="100px"></b-img>

          <b-img :src="require('@/assets/images/tuto/serverless.jpg')" alt="AWS Serverless"  fluid thumbnail class="img-book" width="100px"></b-img>

          <b-img :src="require('@/assets/images/tuto/aws_logo.jpg')" alt="AWS"  fluid thumbnail class="img-book" width="100px"></b-img>
          <b-img :src="require('@/assets/images/tuto/aws_lambda.jpg')" alt="AWS Lambda"  fluid thumbnail class="img-book" width="100px"></b-img>
          <b-img :src="require('@/assets/images/tuto/amazon_s3.png')" alt="AWS S3"  fluid thumbnail class="img-book" width="100px"></b-img>
          <b-img :src="require('@/assets/images/tuto/amplify.jpg')" alt="AWS S3"  fluid thumbnail class="img-book" width="100px"></b-img>
          <b-img :src="require('@/assets/images/tuto/aws_acm.jpg')" alt="AWS ACM"  fluid thumbnail class="img-book" width="100px"></b-img>
          <b-img :src="require('@/assets/images/tuto/cloudfront.jpg')" alt="AWS Cloudfront"  fluid thumbnail class="img-book" width="100px"></b-img>
          <b-img :src="require('@/assets/images/tuto/route_53.jpg')" alt="AWS Route 53"  fluid thumbnail class="img-book" width="100px"></b-img>
          <b-img :src="require('@/assets/images/tuto/dynamo_db.jpg')" alt="AWS Route 53"  fluid thumbnail class="img-book" width="100px"></b-img>
          <b-img :src="require('@/assets/images/tuto/api_gateway.jpg')" alt="AWS API Gateway"  fluid thumbnail class="img-book" width="100px"></b-img>
          <b-img :src="require('@/assets/images/tuto/firebase.jpg')" alt="Firebase"  fluid thumbnail class="img-book" width="100px"></b-img>
          <b-img :src="require('@/assets/images/tuto/stripe.jpg')" alt="Firebase"  fluid thumbnail class="img-book" width="100px"></b-img>
          <b-img :src="require('@/assets/images/tuto/simple_email.jpg')" alt="AWS Simple email service"  fluid thumbnail class="img-book" width="100px"></b-img>

          <b-img :src="require('@/assets/images/tuto/git.svg')" alt="Git"  fluid thumbnail class="img-book" width="100px"></b-img>
          <b-img :src="require('@/assets/images/tuto/github.jpg')" alt="Git"  fluid thumbnail class="img-book" width="100px"></b-img>

        </div>
      </div>
      <!-- Prerequisites -->
      <div class="row mt-4 " style="background-color: #d4f4e5">
        <div class="col m-3">
          <h3><i class="bi bi-skip-forward"></i> Prerequisites</h3>
          <p>None</p>
          <h3><i class="bi bi-tools"></i> Tools Needed</h3>
          <p>A computer with Internet Access</p>
          <p>A code editor (IntelliJ Goland or Visual Studio Code)</p>
        </div>
        <div class="col-1"></div>
      </div>
      <!--What you get -->
      <div class="row mt-4">
        <div class="col-2"></div>
        <div class="col">
          <h2>What is included ?</h2>
          <hr>
          <div class="row">
            <div class="col">
              <i class="bi bi-camera-video what-you-will-learn-icon" ></i> <span v-if="!loading" >{{lessons.length}}</span> on-demand screencasts<br>
              <i class="bi bi-stopwatch what-you-will-learn-icon" ></i> <span v-if="!loading" >{{totalDuration}}</span> of content <br>
              <i class="bi bi-journals what-you-will-learn-icon" ></i> 3 Projects<br>

            </div>
            <div class="col">
              <i class="bi bi-download what-you-will-learn-icon" ></i> Free future Updates<br>
              <i class="bi bi-pencil what-you-will-learn-icon" ></i> Exercises with live correction<br>
              <i class="bi bi-code-slash what-you-will-learn-icon" ></i> Source Code of projects<br>
            </div>
          </div>
        </div>
        <div class="col-2"></div>
      </div>
      <!--Reassurance -->
      <!--start course button -->
      <div class="row mt-4">
        <div class="col-2"></div>
        <div class="col">
          <Loader v-if="loading"></Loader>
          <StartCourseButton v-else  :product-id="this.tutorial.productId"></StartCourseButton>
        </div>
        <div class="col-2"></div>
      </div>
      <!-- Contents  TODO: add buttons de temps en temps,
      - Handle links
      - Payment page
      - page to view
      -->
      <div class="row mt-4">
        <!-- Table of contents -->
        <div class="col-12">
          <Loader v-if="loading"></Loader>
          <TableOfContentTutorial v-else
                                  :lessons="lessons"
                                  :tutorial-id="tutorial.id"
                                  :is-promotional="true"
                                  v-on:click-lesson="onClickLesson"

          ></TableOfContentTutorial>
        </div>
      </div>

      <!--start course button -->
      <div class="row mt-4">
        <div class="col-3"></div>
        <div class="col">
          <Loader v-if="loading"></Loader>
          <StartCourseButton v-else  :product-id="this.tutorial.productId"></StartCourseButton>
        </div>
        <div class="col-3"></div>
      </div>



    </b-container>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import NavBar from "@/components/NavBar";
import WebService from "@/webservice";
import Loader from "@/components/Loader";
import StartCourseButton from "@/components/StartCourseButton";
import Consts from "@/consts";
import TableOfContentTutorial from "@/components/TableOfContentTutorial";
import Webservice from "@/webservice";
import AuthHelper from "@/authHelper";

export default {
  name: "VideoPresentation",
  components: {TableOfContentTutorial, StartCourseButton, Loader, Footer, NavBar},
  props: {
    tutorialId: String
  },
  data() {
    return {
      routeNames: Consts.ROUTE_NAMES,
      productIdTutorial: process.env.VUE_APP_MAIN_TUTORIAL_ID,
      lessons : null,
      tutorial: null,
      loading: false,
      totalDuration: null,
      exactDuration: null,
      mainVideoUrl:"",
    }
  },
  created() {
    window.scrollTo(0,0);
    this.fetchData()
  },
  methods: {
    onClickLesson(lesson){
      AuthHelper.getOrCreateAnonUser().then((user)=> {
        Webservice.tracking({
          action: {
            referrer: document.referrer,
            lang: navigator.language || navigator.userLanguage,
            name: "CLICK_LESSON_PREVIEW_"+this.tutorialId+"_"+lesson.id,
            utm: {
              source: this.$route.query.utm_source,
              medium: this.$route.query.utm_medium,
              campaign: this.$route.query.utm_campaign,
              term: this.$route.query.utm_term,
              content: this.$route.query.utm_content,
            }
          }
        }, user.idToken)
      })
      this.$router.push({name:Consts.ROUTE_NAMES.LESSON_PREVIEW, params:{
          tutorialId:this.tutorialId, lessonId: lesson.id,
        }})
    },
    fetchData(){
      AuthHelper.getOrCreateAnonUser().then((user)=> {
        Webservice.tracking({
          action: {
            referrer: document.referrer,
            lang: navigator.language || navigator.userLanguage,
            name: "VISIT_TUTO_PAGE_"+this.tutorialId,
            utm: {
              source: this.$route.query.utm_source,
              medium: this.$route.query.utm_medium,
              campaign: this.$route.query.utm_campaign,
              term: this.$route.query.utm_term,
              content: this.$route.query.utm_content,
            }
          }
        }, user.idToken)
      })
      this.loading = true
        WebService.getLessonsOfTutorial(process.env.VUE_APP_MAIN_TUTORIAL_ID).then((res)=>{
          this.lessons = res.data.lessons
          this.tutorial = res.data.tutorial
          this.exactDuration = res.data.exactDuration
          this.totalDuration = res.data.totalDuration
          this.mainVideoUrl = res.data.mainVideoUrl
          this.loading = false
        }).catch((err)=> {
          console.error(err)
          this.loading = false
          this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
        })
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: true
      })
    },
  }

}
</script>

<style scoped>
.what-you-will-learn-icon {
  font-size: 1.2rem;
  color: #466644;
}
</style>